import React, { useEffect } from "react";
import { useState } from "react";
import { motion } from "motion/react";

function Service({ service, isDesktop }) {
  const { id, width, icon, container, title, content } = service;
  const [x, setX] = useState(id % 2 == 0 ? 20 : -20);
  const [widthPage, setWidthPage] = useState(width);

  const handleWindowResize = () => {
    if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      setWidthPage(50);
    }
    if (window.innerWidth >= 1024) {
      setWidthPage(width);
    }
    if (window.innerWidth < 768) {
      setWidthPage(100);
    }
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const variantsContent = isDesktop
    ? {
        initial: {
          opacity: 0,
          x: x,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      }
    : {
        initial: {
          x: 0,
          opacity: 1,

        },
        animate: {
          x: 0,
          opacity: 1,

        },
      };

  return (
    <motion.div
      variants={variantsContent}
      initial={variantsContent.initial}
      whileInView={variantsContent.animate}
      viewport={{ once: true, amount: 1 }}
      style={{ width: widthPage + "%" }}
      className={`bg-zinc-900 p-[12px] lg:p-[24px] rounded-[16px] flex flex-col justify-between gap-[12px]`}
    >
      <div className="flex justify-between items-start">
        <img src={icon} alt="icon" />
        <img src={container} alt="container" className="hidden md:block" />
      </div>
      <div>
        <h1 className="font-satoshi font-bold text-lg xl:text-xl tracking-normal text-zinc-100">
          {title}
        </h1>
        <p className="font-satoshi font-normal text-sm xl:text-base tracking-normal text-zinc-400">
          {content}
        </p>
      </div>
    </motion.div>
  );
}

export default Service;
