import React from "react";
import { HashLink } from "react-router-hash-link";

function NavMobile({ links, closeNav }) {
  const closeNavMobile = () => {
    closeNav(false);
  };
  return (
    <div className="z-10 lg:hidden  flex items-start justify-start bg-zinc-900 gap-4 direction-row rounded-[8px] p-[8px] mx-[16px]">
      <ul className="list-none gap-[4px] items-center justify-center lg:flex">
        {links.map((link) => (
          <li
            key={link.id}
            className="px-[8px] py-[8px] hover:underline hover:underline-offset-4 cursor-pointer text-white text-xs font-normal font-satoshi leading-none "
          >
            <HashLink to={link.link} onClick={closeNavMobile}>
              {link.title}
            </HashLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NavMobile;
