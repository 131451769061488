import React from "react";
import NavMobile from "./NavMobile";
import { HashLink } from "react-router-hash-link";
import { navbarLinks } from "../Constants/contentData";


function Navbar({ openNav, onClick }) {
  return (
    <header id="header" className="absolute w-full pt-[24px] left-0 z-50">
      <nav className="flex justify-between mx-auto p-[24px] w-full max-w-[1120px] ">
        <div>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet="../assets/navbar/logo.svg"
            />
            <source
              media="(max-width: 768px)"
              srcSet="../assets/navbar/logo-tablet.svg"
            />
            <img
              src="logo.svg"
              alt="logo"
              className={`mt-1 ${openNav && "blur-[16px] bg-overlayBlack-20 "} lg:blur-none`}
            />
          </picture>
        </div>
        <ul className="hidden  list-none gap-[4px] items-center justify-center lg:flex">
          {navbarLinks.map((link) => (
            <li
              key={link.id}
              className="text-sm cursor-pointer px-[8px] py-[4px] hover:underline hover:underline-offset-4 text-overlayWhite/100"
            >
              <HashLink to={link.link}>{link.title}</HashLink>
            </li>
          ))}
        </ul>
        <button
          className="flex lg:hidden"
          onClick={() => {
            onClick((cur) => !cur);
          }}
        >
          
          <img
            src={
              openNav
                ? `../assets/navbar/close.svg`
                : `../assets/navbar/open.svg`
            }
            alt=""
          />
        </button>
      </nav>
      {openNav && <NavMobile closeNav={onClick} links={navbarLinks} />}
    </header>
  );
}

export default Navbar;
