import React from "react";
import Card from "./Card";
import CustomButton from "./CustomButton";
import { motion } from "motion/react";
import { HashLink } from "react-router-hash-link";
import { projects } from "../Constants/contentData";

function Cards({ isDesktop }) {
  const variantsTitle = isDesktop
    ? {
        initial: {
          opacity: 0,
          y: -100,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }
    : {
        initial: {
          opacity: 0,
          y: 0,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      };

  return (
    <motion.div className="py-[20px] lg:py-[80px] flex flex-col gap-[32px]">
      <motion.div
        variants={variantsTitle}
        initial={variantsTitle.initial}
        whileInView={variantsTitle.animate}
        viewport={{ once: true, amount: 1 }}
        className="xl:w-[50%]"
      >
        <h2 className="font-satoshi font-black text-xl md:text-2xl lg:text-5xl tracking-normal text-overlayWhite-80 w-full ">
          Réalisations concrètes, résultats mesurables
        </h2>
        <span className="w-full font-satoshi text-base lg:text-lg font-medium text-zinc-400 opacity-[80%]">
          Explorez nos succès qui redéfinissent les standards du numérique
        </span>
      </motion.div>
      <div className=" flex flex-col gap-[32px]">
        {projects.map((card, index) => (
          <Card isDesktop={isDesktop} key={index} card={card} />
        ))}
      </div>
      <div className="h-auto bg-gradient-to-b  from-zinc-600 to-zinc-900 mt-[40px] p-[1px] rounded-[12px]">
        <div className="h-full w-full bg-gradient-to-b from-overlayBlack-100 to-zinc-800 p-[16px] rounded-[12px] flex justify-start items-start flex-col gap-[12px] md:gap-[20px]">
          <div className="space-y-[8px]">
            <h2 className="text-lg md:text-xl lg:text-3xl font-satoshi font-black tracking-normal text-overlayWhite-100 ">
              Explorez nos réalisations innovantes
            </h2>
            <span className="font-satoshi font-normal md:font-medium text-sm xl:text-lg tracking-normal text-zinc-400">
              Découvrez comment nous transformons des idées en solutions
              digitales impactantes. Chaque projet raconte une histoire de
              collaboration, de créativité et de succès.
            </span>
          </div>
          <HashLink to="/realisation">
            <CustomButton>Voir nos réalisations →</CustomButton>
          </HashLink>
        </div>
      </div>
    </motion.div>
  );
}

export default Cards;
