import React, {  useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { MotionConfig } from "motion/react";
import { useMediaQuery } from "@mui/material";

function AppLayout() {
  const [isOpenNavbar, setIsOpenNavbar] = useState(false);
  const isDesktop = useMediaQuery("(min-width:1024px)");

  const blur = `${isOpenNavbar ? "blur-[16px] bg-overlayBlack-20 lg:blur-none" : ""}`;
  return (
    <>
      <Navbar openNav={isOpenNavbar} onClick={setIsOpenNavbar} />
      <div className="font-satoshi w-full mx-auto px-[24px] max-w-[1120px]">
        <MotionConfig
          transition={isDesktop && { duration: 0.8, ease: "easeInOut" }}
        >
          <Outlet context={[blur, isDesktop]} />
        </MotionConfig>
      </div>
      <Footer blur={blur} />
    </>
  );
}

export default AppLayout;
