import React from "react";
import { cn } from "../utils/cn";

function CustomButton({
  type = "default",
  children,
  disabled = false,
  classStyle = "",
}) {
  const variants = {
    default:
      "bg-white text-zinc-950 enabled:hover:text-[#34a8fd] active:text-zinc-950 disabled:bg-zinc-800 disabled:text-zinc-700 ",

    secondary:
      "text-zinc-300 bg-zinc-950 enabled:border-[1px] border-zinc-300 enabled:hover:text-[#1e8af2] enabled:hover:bg-zinc-700 hover:border-none disabled:bg-zinc-950 disabled:text-zinc-700 active:bg-zinc-800 active:text-white",

    gost: "text-white  enabled:hover:text-zinc-100 enabled:hover:bg-zinc-800 active:text-[#52525c] active:bg-[#9f9fa9] disabled:text-zinc-700",
  };

  return (
    <button
      disabled={disabled}
      className={cn(
        "flex items-center  justify-center font-bold font-satoshi px-[28px] py-[14px] leading-7 text-nowrap h-[40px] rounded-[12px] text-xs md:h-[44px] md:rounded-[12px] md:text-sm  xl:h-[56px] xl:rounded-[16px] lg:text-lg",
        variants[type],
        classStyle
      )}
    >
      {children}
    </button>
  );
}

export default CustomButton;
