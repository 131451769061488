import React from "react";

function NavbarAboutUs({ info }) {
  const { icon, title } = info;
  return (
    <div className=" bg-gradient-to-t  from-zinc-900 to-zinc-600 p-[1px]  rounded-[8px] lg:rounded-[12px] ">
      <div className=" h-full bg-gradient-to-b from-overlayBlack-100 to-zinc-800 rounded-[8px] lg:rounded-[12px] px-[8px] py-[12px]  md:px-[14px] md:py-[18px] flex justify-between items-center w-full">
        <img src={icon} alt="" />
        <p className="font-satoshi font-bold text-sm md:text-base lg:text-lg tracking-normal text-zinc-300">
          {title}
        </p>
      </div>
    </div>
  );
}

export default NavbarAboutUs;
