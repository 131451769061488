import React from "react";
import Card from "../components/Card";
import Contact from "../components/Contact";
import { useOutletContext } from "react-router-dom";
import { projects } from "../Constants/contentData";
import { Helmet } from "react-helmet";

export default function Projects() {
  const [blur, isDesktop] = useOutletContext();

  return (
    <div className={`space-y-5 ${blur}`}>
      <Helmet>
        <title>Projets Réalisés - KIAM TECH</title>
        <meta
          name="description"
          content="Explorez nos projets récents pour voir comment KIAMTECH a aidé des clients à réussir avec des applications mobiles, des sites web, et des solutions digitales personnalisées."
        />
      </Helmet>

      <div className="w-full h-[80px]" />
      <div className="">
        <h2 className="py-[20px] lg:py-[40px]  text-center font-satoshi font-black text-xl md:text-2xl xl:text-5xl tracking-normal text-overlayWhite-80 w-full ">
          Nos réalisations innovantes
        </h2>
        <div className="flex flex-col gap-[32px] py-[16px] ">
          {projects.map((card, index) => (
            <Card isDesktop={isDesktop} key={index} card={card} />
          ))}
        </div>
        <Contact isDesktop={isDesktop} />
      </div>
    </div>
  );
}
