import React, { useEffect, useState } from "react";
import CustomButton from "./CustomButton";
import { motion } from "motion/react";
import { HashLink } from "react-router-hash-link";
import { iconsClient } from "../Constants/contentData";

function Hero({ isDesktop }) {
  const [height, setHeight] = useState("md:h-screen");
  const variantsImage = isDesktop
    ? {
        initial: {
          scale: 1.6,
          overflowX: "hidden",
        },
        animate: {
          scale: 1,
          zIndex: -1,
          overflow: "hidden",
        },
        transition: {
          duration: 0.8,
          delay: 1,
        },
      }
    : {
        initial: {
          scale: 1,
          zIndex: -1,
          overflow: "hidden",
        },
        animate: {
          scale: 1,
          zIndex: -1,
          overflow: "hidden",
        },
        transition: {
          delay: 0,
          duration: 0,
        },
      };
  const variantsHero = isDesktop
    ? {
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1, y: 0 },
        transition: { delay: 1.2, duration: 0.8 },
      }
    : {
        initial: { opacity: 1, y: 0 },
        animate: { opacity: 1, y: 0 },
        transition: { delay: 0, duration: 0 },
      };
  const variantsTag = isDesktop
    ? {
        initial: { wordSpacing: "0px" },
        animate: { wordSpacing: "1px" },
        transition: { delay: 1.4, duration: 0.8 },
      }
    : {
        initial: { wordSpacing: "0px" },
        animate: { wordSpacing: "1px" },
        transition: { delay: 1.4, duration: 0.8 },
      };
  const variantsIcon = isDesktop
    ? {
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0 },
        transition: { delay: 2, duration: 0.8 },
      }
    : {
        initial: { opacity: 1, y: 0 },
        animate: { opacity: 1, y: 0 },
        transition: { delay: 0, duration: 0 },
      };
  useEffect(() => {
    window.addEventListener("resize",()=>{
      if (window.innerHeight < 900) {
        setHeight("md:h-full");
      }
    })
  }, [height]);
  return (
      <div className={`py-[24px] gap-[24px] flex items-center justify-betwwen flex-col relative h-full ${height}`}>
      <motion.picture
        variants={variantsImage}
        initial={variantsImage.initial}
        animate={variantsImage.animate}
        transition={variantsImage.transition}
        className="absolute z-[-1]"
      >
        <source
          media="(min-width: 1024px)"
          srcSet="../assets/heroSection/heroImages/herosection_desktop_1_5x.webp"
          className=""
        />
        <source
          media="(min-width: 768px)"
          srcSet="../assets/heroSection/heroImages/herosection_tablet_1_5x.webp"
        />
        <source
          media="(max-width: 768px)"
          srcSet="../assets/heroSection/heroImages/herosection_mobile_1_5x.webp"
        />
        <img
          src="hero-section.webp"
          alt="hero-section"
          className="object-contain object-center h-screen "
        />
      </motion.picture>

      <div className="w-full h-[80px]" />
      <motion.div
        variants={variantsHero}
        initial={variantsHero.initial}
        animate={variantsHero.animate}
        transition={variantsHero.transition}
        className="flex flex-grow justify-center flex-col items-center gap-[24px]"
      >
        <motion.p
          variants={variantsTag}
          initial={variantsTag.initial}
          animate={variantsTag.animate}
          transition={variantsTag.transition}
          className="font-satoshi lg:font-black text-xs lg:text-sm text-center font-normal tracking-normal  flex justify-center items-center px-[16px] py-[8px] rounded-[8px] lg:rounded-[12px] text-brandPrimary-950 bg-brandPrimary-300"
        >
          Ouvert à des nouvelles opportunités
        </motion.p>
        <h1 className=" text-3xl lg:text-6xl tracking-normal text-overlayWhite-100 font-black  text-center font-satoshi  ">
          Concrétisez votre vision grâce à des solution numériques évolutives
        </h1>
        <span className="opacity-[80%] font-satoshi font-medium text-base lg:text-lg tracking-normal text-zinc-400 md:w-[704px] w-[256px] text-center">
          Du concept au déploiement, nous bâtissons des outils digitaux qui
          durent.
        </span>
        <div className=" flex flex-col justify-center items-center gap-[20px] md:flex-row w-full md:w-auto">
          <HashLink to="/accueil#services">
            <CustomButton type="secondary" classStyle="w-[100%]">
              Nos services
            </CustomButton>
          </HashLink>
          <HashLink to="/accueil#contact">
            <CustomButton classStyle="w-[100%]">
              Démarrez votre projet →
            </CustomButton>
          </HashLink>
        </div>
      </motion.div>
      <motion.div
        variants={variantsIcon}
        initial={variantsIcon.initial}
        animate={variantsIcon.animate}
        transition={variantsIcon.transition}
        className="xl:w-max flex flex-col gap-[8px] py-[5%] w-full  "
      >
        <span className="text-center font-satoshi font-black text-xs lg:text-sm tracking-normal text-overlayWhite-50 opacity-[50%]">
          Ils nous ont fait confiance
        </span>
        <ul className="flex flex-wrap xl:flex-nowrap justify-center items-center">
          {iconsClient.map((icon) => (
            <li key={icon.id}>
              <img
                className="w-[84px] h-[40px] lg:w-[101.7px] lg:h-[46.8px]"
                src={icon.icon}
                alt="logoClient"
              />
            </li>
          ))}
        </ul>
      </motion.div>
      </div>
  );
}

export default Hero;
