import React from "react";
import Hero from "../components/Hero";
import Cards from "../components/Cards";
import Services from "../components/Services";
import AboutUs from "../components/AboutUs";
import { useOutletContext } from "react-router-dom";
import Process from "../components/Process";
import Contact from "../components/Contact";
import { Helmet } from "react-helmet";
import Offers from "../components/Offers";

function Home() {
  const [blur, isDesktop] = useOutletContext();

  return (
    <div className={`${blur} space-y-5`}>
      <Helmet>
        <title>KIAM TECH - Créateurs de Solutions Digitales Innovantes</title>
        <meta
          name="description"
          content="Bienvenue chez KIAMTECH ! Nous développons des applications mobiles, des sites web et des solutions digitales sur mesure pour répondre aux besoins de votre entreprise."
        />
      </Helmet>

      <Hero isDesktop={isDesktop} />
      <Cards isDesktop={isDesktop} />
      <Services isDesktop={isDesktop} />
      <Offers isDesktop={isDesktop} />
      <AboutUs isDesktop={isDesktop} />
      <Process isDesktop={isDesktop} />
      <Contact isDesktop={isDesktop} />
    </div>
  );
}

export default Home;
