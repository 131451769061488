import React from "react";
import Form from "./Form";
import { motion } from "motion/react";
import { Toaster } from "react-hot-toast";
import {
  EnvelopeSimple,
  InstagramLogo,
  LinkedinLogo,
  PhoneCall,
} from "@phosphor-icons/react";

const formInfo = [
  {
    id: 1,
    icon: (
      <PhoneCall className="text-brandPrimary-500 h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]" />
    ),
    title: "+33 9 77 19 57 61",
  },
  {
    id: 2,
    icon: (
      <EnvelopeSimple className="text-brandPrimary-500 h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]" />
    ),
    title: "contact@kiamtech.com",
  },
  {
    id: 3,
    icon: (
      <InstagramLogo className="text-brandPrimary-500 h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]" />
    ),
    title: "@kiam.tech",
    link: "https://www.instagram.com/kiam.tech/",
  },
  {
    id: 4,
    icon: (
      <LinkedinLogo className="text-brandPrimary-500 h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]" />
    ),
    title: "Kiam-tech",
    link: "https://www.linkedin.com/company/kiam-tech/",
  },
];

function Contact({ style, isDesktop }) {
  const variantsContainer = isDesktop
    ? {
        initial: {
          opacity: 0,
          y: -100,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }
    : {
        initial: {
          opacity: 0,
          y: 0,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      };

  return (
    <motion.div
      variants={variantsContainer}
      initial={variantsContainer.initial}
      whileInView={variantsContainer.animate}
      viewport={{ once: true, mount: 1 }}
      id="contact"
      className="py-[20px] lg:py-[80px] flex flex-col gap-[16px] lg:gap-[40px]"
    >
      <Toaster position="top-center" reverseOrder={false} />

      <div className="flex flex-wrap md:flex-nowrap gap-[16px] lg:gap-[40px]">
        <div className="flex flex-col  gap-[16px] lg:gap-[40px] w-full md:w-[50%] ">
          <div className="flex flex-col gap-[8px]">
            <p className="font-black text-2xl lg:text-5xl text-overlayWhite-80">
              Prêt à concrétiser votre vision ?{" "}
            </p>
            <span className="text-zinc-400 text-base lg:text-lg font-medium">
              Parlons-en ! Notre équipe vous répond sous 24h.
            </span>
          </div>
          <div className="flex flex-col justify-center flex-grow">
            <img src="/assets/aboutUs/aboutus_img.webp" alt="" />
          </div>
        </div>
        <div className="bg-zinc-900 p-[12px] rounded-[12px] w-full md:w-[50%]">
          <Form />
        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
