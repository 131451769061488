import React, { useEffect, useState } from "react";
import Input from "./Input";
import CustomButton from "./CustomButton";
import DropDown from "../ui/DropDown";
import { motion } from "motion/react";
import axios from "axios";
import toast from "react-hot-toast";
// phone number
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMediaQuery } from "@mui/material";

const isPhoneValid = (phone) => {
  const phonePattern =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  return phonePattern.test(phone);
};

const phoneDropdownStyle = {
  top: 48,
  left: 0,
  zIndex: 1,
};

function Form({}) {
  const [disabled, setDisabled] = useState(true);
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");
  const [validNumber, setValidNumber] = useState(true);
  const [number, setNumber] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [prefix, setPrefix] = useState("");
  const isDesktop = useMediaQuery("(min-width:768px)");
  const phoneBtnStyle = {
    color: "#9f9fa9",
    background: "#3f3f46",
    width: 56,
    fontWeight: 700,
    fontSize: 14,
    cursor: "pointer",
    borderTopLeftRadius: isDesktop ? 16 : 8,
    borderBottomLeftRadius: isDesktop ? 16 : 8,
    display: "flex",
    justifyContent: "center",
    borderColor: validNumber ? "#A1A1AA" : "#f87171",
    backgroundColor: "transparent",
  };

  function handleChangePhone(e, phone) {
    setNumber(e);
    if (!isPhoneValid(e)) {
      setErrorNumber("Numéro de téléphone invalide.");
      setCountryCode("");
      setPrefix("");
      setValidNumber(false);
    } else {
      setErrorNumber("");
      setCountryCode(phone.countryCode);
      setPrefix(phone.dialCode);
      setValidNumber(true);
    }
    console.log(phone);
  }

  function resetForm() {
    setFullName("");
    setCompanyName("");
    setEmail("");
    setMessage("");
    setBudget("");
    setNumber("");
    setCountryCode("");
    setPrefix("");
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const emailData = {
      recipient: "contact@kiamtech.com",
      subject: `${fullName} - from contact us page`,
      customer_email: email,
      content: message,
      username: fullName,
      companyName,
      budget,
      telephone: number,
      countryCode,
      prefix,
    };
    try {
      const res = await axios.post("/contact-us", emailData);
      if (res.status === 200) {
        resetForm();
        toast.success("email envoyé avec succés");
      }
    } catch (error) {
      toast.error("L'e-mail n'a pas été envoyé");
      console.log("erreur lors de l'envoi de l email :", error);
    }
  }
  useEffect(() => {
    if (
      fullName &&
      companyName &&
      email &&
      budget &&
      message &&
      number &&
      !errorNumber &&
      !emailError
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    fullName,
    companyName,
    email,
    budget,
    message,
    number,
    errorNumber,
    emailError,
  ]);

  const inputStyle = {
    color: "#9f9fa9",
    background: "#3f3f46",
    width: "100%",
    borderColor: validNumber ? "#A1A1AA" : "#f87171",
    height: 46,
    borderRadius: isDesktop ? 16 : 8,
    paddingLeft: 60,
  };

  return (
    <form className="flex flex-col gap-[16px]" onSubmit={handleSubmit}>
      <div className="flex  flex-col md:flex-row justify-between gap-[16px]">
        <Input
          name="fullName"
          value={fullName}
          onchange={setFullName}
          label="Nom complet"
          placeholder="Jean Dupont"
          typeField="input"
          classStyle="w-full flex-grow md:w-fit"
        />
        <Input
          name="comapnyName"
          value={companyName}
          onchange={setCompanyName}
          label="Nom de l’entreprise"
          placeholder="Ma StartUp Innovante"
          typeField="input"
          classStyle="w-full flex-grow md:w-fit"
        />
      </div>
      <Input
        name="email"
        onError={setEmailError}
        value={email}
        onchange={setEmail}
        label="Email professionnel"
        placeholder="contact@votreentreprise.com"
        typeField="input"
      />
      <div className="flex flex-col gap-[8px]">
        <span className="text-white-90 text-xs font-bold leading-none lg:text-sm lg:leading-[18px] ">
          Numéro de téléphone
        </span>

        <PhoneInput
          country={"ma"}
          name="number"
          value={number}
          onChange={(e, phone) => handleChangePhone(e, phone)}
          inputProps={{ required: true, maxLength: 16 }}
          inputStyle={inputStyle}
          enableSearch={true}
          containerStyle={{}}
          buttonStyle={phoneBtnStyle}
          dropdownStyle={phoneDropdownStyle}
          searchStyle={{}}
        />

        {errorNumber && (
          <span
            className={`text-[#9f9fa9] text-xs font-normal font-satoshi leading-none ${
              errorNumber ? "text-red-400" : ""
            }`}
          >
            {errorNumber}
          </span>
        )}
      </div>
      <div className="flex flex-col gap-[8px]">
        <span className="text-white-90 text-xs font-bold font-satoshi leading-none lg:text-sm lg:leading-[18px] ">
          Budget estimé
        </span>
        <DropDown value={budget} onchange={setBudget} />
      </div>
      <Input
        name="message"
        value={message}
        onchange={setMessage}
        label="Détails du projet"
        placeholder="Décrivez vos objectifs, besoins spécifiques ou fonctionnalités souhaitées"
        typeField="textarea"
      />
      <div className="mt-[16px] lg:mt-[24px]">
        <CustomButton disabled={disabled} classStyle="w-full">
          Envoyer
        </CustomButton>
      </div>
    </form>
  );
}

export default Form;
