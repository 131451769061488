import React from "react";
import { motion } from "motion/react";

const blocksStyle = {
  1: "md:col-span-2 md:row-span-3 ",
  2: "md:col-span-2 md:row-span-2 md:col-start-3 md:row-start-1",
  3: "md:col-span-2 md:row-span-2 md:col-start-1 md:row-start-4",
  4: "md:col-span-2 md:row-span-3 md:col-start-3 md:row-start-3 ",
};

function ProcessCard({ process, isDesktop }) {
  const { id, step, title, content } = process;
  const x = id % 2 == 0 ? 20 : -20;
  const variantsContent = isDesktop
    ? {
        initial: {
          opacity: 0,
          x: x,
          height: "18%",
        },
        animate: {
          opacity: 1,
          x: 0,
          height: "100%",
        },
      }
    : {
        initial: {
          opacity: 1,
          x: 0,
          height: "100%",
        },
        animate: {
          opacity: 1,
          x: 0,
          height: "100%",
        },
      };
  return (
    <motion.div
      variants={variantsContent}
      initial={variantsContent.initial}
      whileInView={variantsContent.animate}
      viewport={{ once: true, amount: 1 }}
      className={` bg-gradient-to-b  from-zinc-600 to-zinc-900  p-[1px] rounded-[24px] w-full ${blocksStyle[id]}`}
    >
      <div className="h-full lg:p-[40px] p-[12px]  bg-zinc-900  gap-[12px]  sm:p-[24px] rounded-[24px] flex justify-between items-start flex-col">
        <span className="border-[2px] text-sm lg:text-base rounded-full py-[4px] px-[16px] text-brandPrimary-600 border-brandPrimary-600">
          {step}
        </span>
        <div className="flex flex-col gap-3">
          <h2 className="font-satoshi font-bold text-base md:text-xl lg:text-[29px] text-overlayWhite-100">
            {title}
          </h2>
          <p className="font-satoshi text-sm lg:text-lg font-normal text-zinc-400">
            {content}
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default ProcessCard;
