import React from "react";
import ProcessCard from "./ProcessCard";
import { motion } from "motion/react";
import { projectSteps as infos } from "../Constants/contentData";

function Process({ isDesktop }) {
  const variantsTitle = isDesktop
    ? {
        initial: {
          opacity: 0,
          y: -100,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }
    : {
        initial: {
          opacity: 0,
          y: 0,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      };

  return (
    <motion.div id="Processus" className="py-[20px] lg:py-[80px] space-y-5">
      <motion.div
        variants={variantsTitle}
        initial={variantsTitle.initial}
        whileInView={variantsTitle.animate}
        viewport={{ once: true, amount: 1 }}
        className="w-full lg:w-[544px]"
      >
        <h2 className="font-satoshi font-black text-xl md:text-2xl lg:text-5xl tracking-normal text-overlayWhite-80 w-full ">
          Notre processus clair et collaboratif{" "}
        </h2>
        <span className="w-full font-satoshi text-base lg:text-lg font-medium text-zinc-400 opacity-[80%]">
          De l’idée à la réalisation : une roadmap transparente pour votre
          succès
        </span>
      </motion.div>
      <div className=" w-full grid grid-cols-1 grid-rows-4 md:grid-cols-4 md:grid-rows-5 gap-[12px] md:gap-[16px] lg:gap-[32px] ">
        {infos.map((process, idx) => (
          <ProcessCard isDesktop={isDesktop} key={idx} process={process} />
        ))}
      </div>
    </motion.div>
  );
}

export default Process;
