import React from "react";
import NavbarAboutUs from "./NavbarAboutUs";
import CustomButton from "./CustomButton";
import { motion } from "motion/react";
import { HashLink } from "react-router-hash-link";
import { experience } from "../Constants/contentData";

function AboutUs({ isDesktop }) {
  // this animation for div Title and div tag of About us
  const variantsTitle = isDesktop
    ? {
        initial: {
          opacity: 0,
          y: -100,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }
    : {
        initial: {
          opacity: 0,
          y: 0,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      };
  // this animation for div image
  const variantsImage = isDesktop
    ? {
        initial: {
          opacity: 0,
          scale: 0,
        },
        animate: {
          opacity: 1,
          scale: 1,
        },
      }
    : {
        initial: {
          opacity: 1,
          scale: 1,
        },
        animate: {
          opacity: 1,
          scale: 1,
        },
      };
  const variantsText = isDesktop
    ? {
        initial: {
          opacity: 0,
          y: 100,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }
    : {
        initial: {
          opacity: 1,
          y: 0,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      };
  return (
    <motion.div id="aboutUs" className="py-[20px] lg:py-[80px] space-y-5">
      <div className="flex items-center justify-between flex-row w-full ">
        <motion.div
          variants={variantsTitle}
          initial={variantsTitle.initial}
          whileInView={variantsTitle.animate}
          viewport={{ once: true, amount: 1 }}
          className="w-full lg:w-[544px]"
        >
          <h2 className="font-satoshi font-black text-xl md:text-2xl lg:text-5xl tracking-normal text-overlayWhite-80">
            Kiam Tech : bien plus qu’une agence tech
          </h2>
          <p className="font-satoshi font-medium text-base xl:text-lg tracking-normal text-zinc-400">
            L’alliance parfaite entre expertise technique et vision humaine
          </p>
        </motion.div>
        <div></div>
        <motion.div
          variants={variantsImage}
          initial={variantsImage.initial}
          whileInView={variantsImage.animate}
          viewport={{ once: true, amount: 1 }}
        >
          <img
            src="./assets/aboutUs/aboutus_img.webp"
            className="h-[169px] hidden lg:flex items-center justify-center"
            alt=""
          />
        </motion.div>
      </div>
      <div className="flex flex-col md:flex-row md:flex-wrap">
        <motion.div
          variants={variantsText}
          initial={variantsText.initial}
          whileInView={variantsText.animate}
          viewport={{ once: true, amount: 1 }}
          className="w-[100%] lg:w-[60%] text-base md:text-lg font-satoshi font-medium tracking-normal text-zinc-400"
        >
          <p>
            Chez Kiam Tech, notre mission transcende la simple création de sites
            web et d'applications mobiles.
            <br /> Nous sommes animés par une passion profonde pour le numérique
            et une volonté inébranlable d'innover.
            <br />
            Notre objectif ? Transformer votre vision en réalité digitale,
            propulsant votre entreprise vers de nouveaux sommets.
          </p>
          <br />
          <p>
            Notre équipe va au-delà du simple développement, nous élaborons des
            solutions personnalisées qui répondent précisément à vos besoins.
            Chaque projet est une aventure, où votre succès devient le nôtre.
          </p>
          <br />
          <p>
            Chez Kiam Tech, nous croyons à l'importance de l'écoute et de
            l'adaptation. Nous plongeons au cœur de votre industrie pour en
            comprendre chaque nuance, chaque défi.
            <br /> Notre méthodologie agile, combinée à une veille technologique
            constante, nous permet de vous offrir des solutions avant-gardistes,
            efficaces et évolutives.
          </p>
          <br />
          <p>
            L'innovation est au cœur de tout ce que nous faisons, mais notre
            véritable différence réside dans nos valeurs fondamentales :
            transparence, intégrité et engagement.
            <br /> Nous construisons des partenariats solides, basés sur la
            confiance et le respect mutuel
          </p>
        </motion.div>
        <div className="w-[100%] lg:w-[40%] py-[16px] lg:p-[20px] flex flex-col md:flex-row lg:flex-col justify-between gap-[12px]">
          <motion.div
            variants={variantsTitle}
            initial={variantsTitle.initial}
            whileInView={variantsTitle.animate}
            viewport={{ once: true, amount: 1 }}
            className="flex flex-col flex-wrap md:flex-nowrap gap-[16px] md:w-[60%] lg:w-full justify-evenly  lg:full"
          >
            {experience.map((info, idx) => (
              <NavbarAboutUs key={idx} info={info} />
            ))}
          </motion.div>
          <motion.div
            variants={variantsText}
            initial={variantsText.initial}
            whileInView={variantsText.animate}
            viewport={{ once: true, amount: 1 }}
            className="md:w-full lg:w-full bg-zinc-900 p-[12px] lg:p-[20px] rounded-[12px] lg:rounded-[24px]  flex flex-col md:gap-[12px]"
          >
            <p className="text-base lg:text-lg font-bold tracking-normal text-zinc-50 underline underline-offset-[1px] decoration-zinc-50 ">
              Et vous ?
            </p>
            <p className="font-normal text-base lg:text-lg tracking-normal text-zinc-400">
              Prêt à collaborer avec un partenaire qui{" "}
              <span className="font-bold text-base lg:text-lg tracking-normal text-zinc-200">
                transforme vos idées en avantage concurrentiel ?<br /> Contactez
                Kiam Tech dès aujourd’hui–
              </span>{" "}
              Ensemble, façonnons l’avenir de votre entreprise.
            </p>
            <HashLink to="/accueil#contact">
              <CustomButton classStyle="w-full">
                Démarrez votre transformation →{" "}
              </CustomButton>
            </HashLink>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default AboutUs;
