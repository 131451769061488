import React from "react";
import CustomButton from "./CustomButton";
import { SealCheck } from "@phosphor-icons/react";
import { motion } from "motion/react";
import { HashLink } from "react-router-hash-link";

function Offer({ offersData, isDesktop }) {
  const variantsContainer = isDesktop
    ? {
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
      }
    : {
        initial: {
          opacity: 1,
        },
        animate: {
          opacity: 1,
        },
      };
  const variantsImage = isDesktop
    ? {
        initial: {
          opacity: 0,
          x: -100,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      }
    : {
        initial: {
          opacity: 1,
          x: 0,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      };
  const variantsContent = isDesktop
    ? {
        initial: {
          opacity: 0,
          x: 100,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      }
    : {
        initial: {
          opacity: 1,
          x: 0,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      };

  return (
    <div className="flex flex-col gap-[40px]">
      {offersData.map((data, idx) => (
        <motion.div
          variants={variantsContainer}
          initial={variantsContainer.initial}
          whileInView={variantsContainer.animate}
          viewport={{ once: true, amount: 1 }}
          key={idx}
          className=" bg-gradient-to-t  from-zinc-900 to-zinc-600 p-[1px]  rounded-xl md:rounded-2xl lg:rounded-3xl "
        >
          <motion.div className=" h-full bg-zinc-900 flex-wrap md:flex-nowrap rounded-xl md:rounded-2xl lg:rounded-3xl p-[6px] md:p-3 flex gap-2 lg:gap-3">
            <motion.div
              variants={variantsImage}
              initial={variantsImage.initial}
              whileInView={variantsImage.animate}
              viewport={{ once: true, amount: 1 }}
              className="flex flex-col flex-wrap gap-8 p-3 lg:p-4 rounded-xl bg-zinc-800 md:w-[50%] h-fit"
            >
              <div className="flex flex-col gap-2 text-brandPrimary-500">
                {data.icon}
                <p className="font-blod text-lg lg:text-xl text-brandPrimary-500">
                  {data.website}
                </p>
              </div>
              <div className="flex flex-col gap-[4px]">
                <span className="font-medium text-xl text-zinc-600">
                  {data.label_price}
                </span>
                <p className="font-black text-xl lg:text-3xl text-overlayWhite-100">
                  {data.price}
                </p>
                <div className="border-[1px] border-zinc-600 border-dashed" />
              </div>
              <p className="font-normal text-sm lg:text-xl text-zinc-400">
                {data.desc}
              </p>
            </motion.div>
            <motion.div
              variants={variantsContent}
              initial={variantsContent.initial}
              whileInView={variantsContent.animate}
              viewport={{ once: true, amount: 1 }}
              className="flex flex-col gap-[16px] lg:gap-[24px] p-[6px] md:p-[12px] lg:p-[16px] rounded-[6px] md:rounded-[8px] lg:rounded-[12px] bg-zinc-800 md:w-[50%]"
            >
              <div className="flex flex-col gap-[12px] lg:gap-[16px]">
                {data.benefits.map((benefit, idx) => (
                  <div key={idx} className="flex gap-[8px] items-center">
                    <div>
                      <SealCheck className="w-[20px] h-[20px] md:h-[24px] md:w-[24px] lg:w-[32px] lg:h-[32px] text-brandPrimary-500" />
                    </div>
                    <p className="text-base md:text-base lg:text-lg font-medium text-zinc-100">
                      {benefit}
                    </p>
                  </div>
                ))}
                <HashLink to="/accueil/#contact">
                  <CustomButton classStyle="w-full">{data.button}</CustomButton>
                </HashLink>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      ))}
    </div>
  );
}

export default Offer;
