import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import Tags from "../components/Tags";
import CustomButton from "../components/CustomButton";
import Contact from "../components/Contact";
import { projects } from "../Constants/contentData";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Heading,
  HeadingTwo,
  hrLine,
  liList,
  Text,
  ulList,
} from "../utils/data.js";
import { Helmet } from "react-helmet";

function ProjectDetails() {
  const { projetId } = useParams();
  const [info, setInfo] = useState(
    projects.find((project) => project.id == projetId)
  );
  const [blur, isDesktop] = useOutletContext();

  function hanldeOpen(url) {
    window.open(url, "_blank");
  }

  return (
    <div className={`space-y-5 ${blur}`}>
      <Helmet>
        <title>{info.name} - KIAM TECH</title>
        <meta name="description" content={info.desc} />
      </Helmet>

      <div className="w-full h-[100px]" />
      <div className="py-[20px] lg:py-[40px]  flex flex-col gap-[12px] lg:gap-[32px]">
        <div className="md:h-[22px] flex flex-row justify-between flex-wrap">
          <div className="py-[16px] md:py-0">
            <img src={`../${info.icon}`} className="" alt="logo" />
          </div>
          <div className="md:h-[22px]">
            <Tags tags={info.tags} />
          </div>
        </div>
        <div className="h-[1px] w-full bg-zinc-300 "></div>
        <div>
          <h1 className="font-black text-3xl lg:text-6xl tracking-normal text-overlayWhite-100">
            {info.title}
          </h1>
        </div>
      </div>
      <div className="py-[20px] lg:py-[40px] flex gap-[16px] lg:gap-[32px] justify-center flex-wrap md:flex-nowrap ">
        {info.youtubeLink_embeded && (
          <div className="w-full md:w-[50%] flex flex-col justify-between gap-[16px] md:gap-0">
            <iframe
              className="h-[306px] md:h-full"
              src={info.youtubeLink_embeded}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />

            <div
              className="w-full"
              onClick={() => hanldeOpen(info.websiteLink)}
            >
              <CustomButton type="default" classStyle="w-full">
                {info.websiteLink}
              </CustomButton>
            </div>
          </div>
        )}
        <div className="w-full  md:w-[50%]">
          <img className="rounded-[12px]" src={`../${info.image}`} alt="" />
        </div>
      </div>
      <div className="py-[20px] lg:py-[40px] text-base lg:text-lg flex flex-col gap-[16px]">
        <ReactMarkdown
          remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
          components={{
            h1: Heading,
            h2: HeadingTwo,
            p: Text,
            hr: hrLine,
            ul: ulList,
            li: liList,
          }}
        >
          {info.desc}
        </ReactMarkdown>
      </div>

      <div className="py-[20px] lg:py-[40px]">
        <Contact isDesktop={isDesktop} style="w-[100%]" />
      </div>
    </div>
  );
}

export default ProjectDetails;
