import React, { useState } from "react";

const isEmailValid = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

function Input({
  classStyle,
  name,
  value,
  label = "",
  placeholder = "",
  typeField = "input",
  disabled = false,
  onchange,
  type,
  onError,
}) {
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  function handleChange(e) {
    onchange(e.target.value);
    // Validate if the input is not empty
    if (!e.target.value.trim()) {
      setError(true);
      setErrorMessage("Ce champ est requis.");
      setValid(false);
    } else {
      setError(false);
      setErrorMessage("");
      setValid(true);
    }
    if (name === "email") {
      if (!isEmailValid(e.target.value)) {
        setError(true);
        setErrorMessage("Cet email n'est pas valide.");
        onError("Cet email n'est pas valide.");
        setValid(false);
      } else {
        setError(false);
        setErrorMessage("");
        onError("");
        setValid(true);
      }
    }
  }
  return (
    <div
      className={`flex flex-col justify-start items-start gap-[8px] ${classStyle}`}
    >
      {label.length > 1 && (
        <label
          htmlFor="input"
          className="text-white-90 text-xs font-bold font-satoshi leading-none lg:text-sm lg:leading-[18px] "
        >
          {label}
        </label>
      )}
      {typeField === "input" && (
        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          className={`${valid ? "border-brandPrimary-500" : ""} ${
            error ? "border-red-400 focus:border-red-400" : ""
          } border-zinc-400 h-[46px] w-full flex items-center justify-center p-[10px] lg:p-[14px] text-[#9f9fa9] rounded-[8px] md:rounded-2xl border-[1px]  bg-zinc-700  placeholder:text-[#9f9fa9] placeholder:text-sm font-normal font-satoshi leading-none lg:leading-[18px] focus:outline-none focus:border-brandPrimary-500 focus:text-white text-xs lg:text-sm lg:font-normal  focus:leading-[18px] disabled:text-zinc-700 disabled:bg-zinc-800 disabled:border-zinc-600 hover:border-zinc-300`}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
      {typeField === "textarea" && (
        <textarea
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}
          className={`${valid ? "border-brandPrimary-500" : ""} ${
            error ? "border-red-400 focus:border-red-400" : ""
          } border-zinc-400 w-full flex items-center justify-center p-[10px] lg:p-[14px] rounded-[8px] md:rounded-2xl bg-zinc-700 placeholder:text-[#9f9fa9] placeholder:text-xm lg:placeholder:text-sm   leading-[18px] focus:outline-none focus:border-brandPrimary-500 focus:text-white text-xs lg:text-sm font-medium lg:font-normal font-satoshi focus:leading-[18px] disabled:text-zinc-700 disabled:bg-zinc-800 disabled:border-zinc-600 border-[1px] hover:border-zinc-300`}
          rows={10}
        />
      )}
      {error && (
        <span
          className={`text-[#9f9fa9] text-xs font-normal font-satoshi leading-none ${
            !disabled && error ? "text-red-400" : ""
          }`}
        >
          {errorMessage}
        </span>
      )}
    </div>
  );
}

export default Input;
