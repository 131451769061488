import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { termsAndConditions } from '../Constants/contentData'
function Terms() {
  const [blur] = useOutletContext()
  return (
    <div className={`space-y-5 ${blur}`}>
      <div className="w-full h-[100px]" />
      <h1 className='py-[20px] lg:py-[40px] text-center text-3xl lg:text-6xl font-black text-overlayWhite-100'>Termes de service</h1>
        <div className='py-[20px] lg:py-[40px] font-medium text-base lg:text-lg text-zinc-400'>
          {termsAndConditions.map((term,idx)=>(
            <>
            <p key={idx}>{term}</p>
            <br/>
            </>
            ))}
        </div>
    </div>
  )
}

export default Terms