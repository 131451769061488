import React from "react";
import Service from "./Service";
import { motion } from "motion/react";
import { services } from "../Constants/contentData";
function Services({ isDesktop }) {
  const variantsTitle = isDesktop
    ? {
        initial: {
          opacity: 0,
          y: -100,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }
    : {
        initial: {
          opacity: 1,
          y: 0,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      };
  return (
    <motion.div id="services" className="py-[20px] lg:py-[80px] space-y-5">
      <motion.div
        variants={variantsTitle}
        initial={variantsTitle.initial}
        whileInView={variantsTitle.animate}
        viewport={{ once: true, amount: 1 }}
        className="flex items-start justify-start flex-col w-full xl:w-[445px]"
      >
        <h2 className="font-satoshi font-black text-xl md:text-2xl lg:text-5xl tracking-normal text-overlayWhite-80">
          Nos services tech
        </h2>
        <p className="font-satoshi font-medium text-base lg:text-lg tracking-normal text-zinc-400">
          Services clé en main pour construire, optimiser et sécuriser votre
          écosystème digital
        </p>
      </motion.div>
      <div className="space-y-[12px] lg:space-y-[32px] py-[20px]">
        {services.map((service, idx) => (
          <div
            key={idx}
            className="flex flex-wrap md:flex-nowrap gap-[12px] lg:gap-[32px]"
          >
            {service.div.map((service, idx) => (
              <Service isDesktop={isDesktop} service={service} key={idx} />
            ))}
          </div>
        ))}
      </div>
    </motion.div>
  );
}

export default Services;
