import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/globalStyle";
import Home from "./screens/Home";
import AppLayout from "./containers/AppLayout";
import Projects from "./screens/Projects";
import ProjectDetails from "./screens/ProjectDetails";
import Terms from "./screens/Terms";
import Utilisation from "./screens/Utilisation";
import Confidentiality from "./screens/Confidentiality";
import Cookies from "./screens/Cookies";


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div class="container">
          <Router>
            <Routes>
              <Route element={<AppLayout/>} >
                <Route index element={<Navigate to={"/accueil"}/>} />
                <Route path="/accueil"  element={<Home/>}/>
                <Route path="/realisation" element={<Projects/>}/>
                <Route path="/projet/:projetId" element={<ProjectDetails/>}/>
                <Route path="/termes" element={<Terms/>}/>
                <Route path="/politique-d'utilisation" element={<Utilisation/>}/>
                <Route path="/politique-Confidentialite" element={<Confidentiality/>}/>
                <Route path="/politique-cookies" element={<Cookies/>}/>
                <Route path="*" element={<Navigate to="/accueil"/>}/>
              </Route>
            </Routes>
          </Router>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
