import React from "react";

function Tags({ tags }) {
  return (
    <ul className="flex flex-wrap gap-[8px] xl:gap-[12px] ">
      {tags.map((icon) => (
        <li
          key={icon.id}
          className="px-[8px] py-[2px] font-sans font-light text-xs xl:text:sm tracking-normal text-center text-zinc-300 border-[1px] border-zinc-300 rounded-full"
        >
          {icon.name.toUpperCase()}
        </li>
      ))}
    </ul>
  );
}

export default Tags;
