import {
  Browser,
  Browsers,
  DeviceMobileSpeaker,
  SealCheck,
  ShoppingBag,
  ShoppingCart,
} from "@phosphor-icons/react";

import React from "react";

import Offer from "./Offer";
import { motion } from "motion/react";

const offersData = [
  {
    id: 1,
    website: "Site Vitrine",
    label_price: "Prix à partir de : ",
    price: "1 400 €",
    desc: "Idéal pour les startups, freelances et PME qui veulent briller sans se ruiner.",
    button: "Créer ma vitrine pro →",
    icon: (
      <Browser className="w-[36px] h-[36px] md:w-[48px] md:h-[48px] lg:w-[64px] lg:h-[64px]" />
    ),
    benefits: [
      "5 pages percutantes : Accueil, Services, À propos, Blog, Contact (et même une page 404 stylisée !).",
      "SEO optimisé : Mots-clés stratégiques, balises meta, sitemap et robots.txt pour dominer Google.",
      "Design moderne et responsive : Adapté à tous les écrans, avec votre identité visuelle en vedette.",
      "Formulaire connecté : Intégration CRM/messagerie pour capturer chaque lead.",
      "2 révisions incluses : Ajustez le design et le contenu pour un résultat parfait.",
    ],
  },
  {
    id: 2,
    website: "Site E-commerce",
    label_price: "Prix à partir de : ",
    price: "2,800 €",
    desc: "Idéal pour les entrepreneurs prêts à conquérir le marché en ligne.",
    button: "Lancer mon e-commerce →",
    icon: (
      <ShoppingCart className="w-[36px] h-[36px] md:w-[48px] md:h-[48px] lg:w-[64px] lg:h-[64px]" />
    ),
    benefits: [
      "Catalogue illimité : Gérez produits, stocks et commandes en temps réel.",
      "Paiements sécurisés : Stripe, PayPal et solutions sur mesure pour rassurer vos clients.",
      "Interface admin pro : Ajoutez des produits en 3 clics, suivez les ventes et les stats.",
      "SEO boosté : Balises meta optimisées, sitemap et vitesse de chargement éclair.",
      "3 révisions incluses : Personnalisez les fonctionnalités pour un site unique.",
    ],
  },
  {
    id: 3,
    website: "CRM Sur Mesure",
    label_price: "Prix ;",
    price: "Sur devis",
    desc: "Parfait pour les entreprises ambitieuses qui veulent scaler intelligemment.",
    button: "Créer ma vitrine pro →",
    icon: (
      <Browsers className="w-[36px] h-[36px] md:w-[48px] md:h-[48px] lg:w-[64px] lg:h-[64px]" />
    ),
    benefits: [
      "Tableau de bord temps réel : Graphiques dynamiques (Recharts) pour suivre ventes et ROI",
      "Gestion 360° des clients : Historique des interactions, suivi des leads et facturation automatisée.",
      "Design Shaden élégant : Interface professionnelle et 100% personnalisable.",
      "Rapports exportables : PDF, Excel ou directement dans votre boîte mail.",
    ],
  },
  {
    id: 4,
    website: "App Mobile Premium",
    label_price: "Prix ;",
    price: "Sur devis",
    desc: "Idéal pour les marques innovantes qui veulent engager et fidéliser.",
    button: "Développer mon app →",
    icon: (
      <DeviceMobileSpeaker className="w-[36px] h-[36px] md:w-[48px] md:h-[48px] lg:w-[64px] lg:h-[64px]" />
    ),
    benefits: [
      "Cross-platform (iOS/Android) : Développement rapide avec React Native + Expo.",
      "SEO optimisé : Mots-clés stratégiques, balises meta, sitemap et robots.txt pour dominer Google.",
      "Design moderne et responsive : Adapté à tous les écrans, avec votre identité visuelle en vedette.",
      "Formulaire connecté : Intégration CRM/messagerie pour capturer chaque lead.",
      "2 révisions incluses : Ajustez le design et le contenu pour un résultat parfait.",
    ],
  },
];

function Offers({ isDesktop }) {
  const variantsTitle = isDesktop
    ? {
        initial: {
          opacity: 0,
          y: -100,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }
    : {
        initial: {
          opacity: 1,
          y: 0,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      };
  return (
    <div className="py-[20px] lg:py-[80px] flex flex-col gap-[40px]">
      <motion.div
        variants={variantsTitle}
        initial={variantsTitle.initial}
        whileInView={variantsTitle.animate}
        viewport={{ once: true, amount: 1 }}
        className="w-full lg:w-[544px]"
      >
        <h2 className="font-satoshi font-black text-xl md:text-2xl lg:text-5xl tracking-normal text-overlayWhite-80 w-full ">
          Découvrez nos offres tarifaires
        </h2>
        <span className="w-full font-satoshi text-base lg:text-lg font-medium text-zinc-400 opacity-[80%]">
          Des offres conçues pour booster votre business, avec transparence et
          expertise !
        </span>
      </motion.div>
      <Offer offersData={offersData} isDesktop={isDesktop} />
    </div>
  );
}

export default Offers;
