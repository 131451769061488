import React, { useEffect, useState } from "react";
import CustomButton from "./CustomButton";
import { HashLink } from "react-router-hash-link";
import { footer } from "../Constants/contentData";

function Footer({ blur }) {
  const [hidden, setHidden] = useState("hidden");
  function handleChange() {
    window.scroll(0, 0);
  }
  function showDiv() {
    setHidden("block");
  }
  function toUp() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  useEffect(() => {
    setTimeout(showDiv, 2000);
    return () => clearTimeout(showDiv);
  }, []);

  return (
    <footer
      className={`${hidden} py-[20px] mt-5 w-full mx-auto px-[24px] max-w-[1120px] space-y-5 bg-zinc-950 flex flex-col lg:gap-[48px] ${blur}`}
    >
      <div className="flex justify-center md:justify-between items-center flex-col md:flex-row ">
        <picture>
          <source
            media="(min-width: 1024px)"
            srcSet="../assets/footer/logo-desktop.svg"
          />
          <source
            media="(min-width: 768px)"
            srcSet="../assets/footer/logo-tablet.svg"
          />
          <source
            media="(max-width: 768px)"
            srcSet="../assets/footer/logo-mobile.svg"
          />
          <img src="logo.svg" alt="logo" />
        </picture>
        <div onClick={toUp}>
          <CustomButton type="gost">Routeur au sommet</CustomButton>
        </div>
      </div>
      <div className="flex md:justify-between flex-col md:flex-row">
        {footer.map((elt) => (
          <div key={elt.id}>
            <h1 className="text-zinc-100 text-lg text-semibold font-normal lg:text-3xl lg:font-bold font-satoshi leading-9 mb-[4px]">
              {elt.title}
            </h1>
            <ul className="flex flex-col gap-[12px]">
              {elt.items.map((item) => (
                <li
                  key={item.id}
                  className="cursor-pointer hover:underline hover:underline-offset-4 text-zinc-400 text-sm lg:text-base font-normal font-satoshi leading-normal"
                  onClick={handleChange}
                >
                  <HashLink to={item.link}>{item.item}</HashLink>
                </li>
              ))}
              {elt.icons && (
                <li className="flex gap-4">
                  {elt.icons.map((icon) => (
                    <a
                      key={icon.id}
                      href={icon.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <icon.Icon
                        size={32}
                        weight="duotone"
                        className="text-white hover:text-blue-400 transition-colors"
                      />
                    </a>
                  ))}
                </li>
              )}
            </ul>
          </div>
        ))}
      </div>
      <p className="text-center text-zinc-400 text-sm lg:text-lg font-normal font-satoshi leading-7 mt-[20px]">
        KimTech@Copyright 2025
      </p>
    </footer>
  );
}

export default Footer;
