import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 1220,
      lg: 1440,
      xl: 1920,
      custom: 1440,
    },
  },
});

export const Fontsize = {
  DisplayLarge: 52,
  DisplaySmall: 44,
  H1Desktop: 40,
  H1Mobile: 36,
  H2Desktop: 36,
  H2Mobile: 32,
  H3Desktop: 32,
  H3Mobile: 28,
  H4Desktop: 28,
  H4Mobile: 24,
  H5Desktop: 24,
  H5Mobile: 20,
  H6Desktop: 20,
  H6Mobile: 18,
  ParagraphLarge: 18,
  ParagraphMedium: 16,
  ParagraphSmall: 14,
  ParagraphXSmall: 12,
  ParagraphOverlineLarge: 14,
  ParagraphOverlineSmall: 12,
  LabelLarge: 16,
  LabelMedium: 14,
  LabelSmall: 12,
  LabelXSmall: 10,
};

export const palette = {
  
  
};
