import React from "react";
import CustomButton from "./CustomButton";
import Tags from "./Tags";
import { motion } from "motion/react";
import { HashLink } from "react-router-hash-link";

function Card({ card, isDesktop }) {
  const { id, image, icon, title, tags } = card;
  const variantsContainer = isDesktop
    ? {
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
        whileHover: {
          scale: 1.02,
        },
      }
    : {
        initial: {
          opacity: 1,
        },
        animate: {
          opacity: 1,
        },
        whileHover: {
          scale: 1,
        },
      };
  const variantsImage = isDesktop
    ? {
        initial: {
          opacity: 0,
          x: -100,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      }
    : {
        initial: {
          opacity: 1,
          x: 0,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      };
  const variantsContent = isDesktop
    ? {
        initial: {
          opacity: 0,
          x: 100,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      }
    : {
        initial: {
          opacity: 1,
          x: 0,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
      };

  function handleChange() {
    window.scroll(0, 0);
  }
  return (
    <motion.div
      variants={variantsContainer}
      initial={variantsContainer.initial}
      whileInView={variantsContainer.animate}
      viewport={{ once: true, amount: 1 }}
      whileHover={variantsContainer.whileHover}
      transition={{ duration: 0.2 }}
      className={`rounded-[20px] md:rounded-[28px] lg:rounded-[32px] p-[16px] lg:p-[20px] bg-zinc-900 flex gap-[12px] flex-wrap lg:gap-[24px] md:gap-[16px] sm:flex-nowrap ${
        id % 2 === 0 ? "flex-row-reverse justify-end" : "flex-row"
      }`}
    >
      <motion.div
        variants={variantsImage}
        initial={variantsImage.initial}
        whileInView={variantsImage.animate}
        viewport={{ once: true, amount: 1 }}
        className="w-[100%] sm:w-[50%] rounded-xl xl:rounded-2xl flex justify-center items-center md:gap-[16px]"
      >
        <img
          className="w-[100%] h-full object-cover rounded-2xl"
          src={image}
          alt="project_id_2"
        />
      </motion.div>
      <motion.div
        variants={variantsContent}
        initial={variantsContent.initial}
        whileInView={variantsContent.animate}
        viewport={{ once: true, amount: 1 }}
        className="md:py-[20px] flex flex-col justify-between gap-[12px] md:gap-[16px]  w-[100%] sm:w-[50%]"
      >
        <div className="space-y-3 md:space-y-[16px]">
          <img
            src={icon}
            className="w-[126.81px] xl:w-[202.89px] h-[20px] xl:h-[32px]"
            alt="logo"
          />
          <h2 className="font-extrabold md:font-black text-base md:text-xl lg:text-3xl tracking-normal text-overlayWhite-100 w-full">
            {title}
          </h2>
          <div className="flex flex-col gap-3">
            <div className="h-[1px] bg-zinc-300 w-full" />
            <Tags tags={tags} />
          </div>
        </div>
        <div className="w-full pt-[32px] md:pt-0">
          <HashLink to={`/projet/${id}`} onClick={handleChange}>
            <CustomButton
              type="secondary"
              classStyle="w-full md:w-auto border-zinc-800"
            >
              Voir le Projet
            </CustomButton>
          </HashLink>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Card;
