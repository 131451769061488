import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CaretDown } from "@phosphor-icons/react";

const dropDownData = [
  {
    id: 1,
    label: "€700-€1.2k",
  },
  {
    id: 2,
    label: "€1.2k-€2k",
  },
  {
    id: 3,
    label: "€3k-€5k",
  },
  {
    id: 4,
    label: "more than €5k",
  },
];

function DropDown({ onchange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState("Sélectionnez votre budget de projet");

  function toggleDropdown(e) {
    e.preventDefault();
    setIsOpen((cur) => !cur);
  }
  function showData(id) {
    let title = dropDownData.find((data) => data.id == id).label;
    setLabel(title);
    onchange(title);
    setIsOpen(false);
  }
  return (
    <div className="relative inline-block  w-full">
      <div className="w-full">
        <button
          onClick={toggleDropdown}
          className="p-[10px] md:p-[14px] border-[1px] h-[46px] rounded-lg md:rounded-2xl bg-zinc-700 border-zinc-400 flex items-center  gap-[8px] w-full outline-none text-xs md:text-sm font-normal text-zinc-400 focus:border-brandPrimary-500"
        >
          <p className="w-full text-start ">{label}</p>
          <CaretDown weight="duotone" size={20} />
        </button>
      </div>

      {isOpen && (
        <AnimatePresence>
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="origin-top-right absolute overflow-hidden right-0 mt-2 w-full 
                    rounded-2xl shadow-lg bg-zinc-700 border-[1px] border-zinc-400 outline-none"
            role="menu"
          >
            {dropDownData.map((data, idx) => (
              <motion.span
                key={idx}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2, delay: idx * 0.05 }}
                className="block p-[14px] text-sm text-zinc-400 cursor-pointer hover:bg-zinc-600"
                onClick={() => showData(data.id)}
              >
                {data.label}
              </motion.span>
            ))}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default DropDown;
