export const Heading = ({children})=>{
    return  <h1 className="font-black text-lg lg:text-xl text-overlayWhite-100">{children}</h1>
}
export const HeadingTwo = ({children})=>{
    return  <h1 className="font-medium text-base lg:text-lg text-zinc-400">{children}</h1>
}

export const Text = ({children})=>{
    return  <p className="font-medium text-base lg:text-lg text-zinc-400">{children}</p>
}
export const hrLine = ({children})=>{
    return  <div className="h-[1px] w-full bg-zinc-300 ">{children}</div>
}
export const ulList = ({children})=>{
    return  <ul className="list-none list-inside ">{children}</ul>
}
export const liList = ({children})=>{
    return  <ul className="px-[1px] font-medium text-base lg:text-lg text-zinc-400">{children}</ul>
}
